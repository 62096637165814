<template>
  <div>
    <iframe :src="signUrl" frameborder="0" width="100%" height="700px"></iframe>
  </div>
</template>

<script>
export default {
  created() {
    this.signUrl = this.$storage.getItem('signUrl')
    this.handleSuccess()
  },
  data() {
    return {
      signUrl: '',
    }
  },
  beforeDestroy() {
    window.removeEventListener('message', () => {}, false)
  },
  methods: {
    handleSuccess() {
      window.addEventListener('message', e => {
        if (e.data === 'SUBMIT_SUCCESS') {
          this.$message.success('签约成功')
          this.$router.push('/bill-financing')
        }
      })
    },
  },
}
</script>
